<template>
    <div class="flex column center" style="margin-bottom:4rem;">
        <div class="new_box">
            <div class="titlee">司法部关于发布实施《司法鉴定标准化工作指南》等35项行业标准的公告</div>
            <p class="time">来源：司法部官网 发布时间：2021-12-07</p>
            <p class="space_content">
              为规范全国司法行政信息化建设，加强对全国司法行政业务工作的指导，司法部制定了《司法鉴定标准化工作指南》等19项行业标准，修订了《法医临床检验规范》等16项行业标准。现将上述35项标准予以发布，自2021年11月17日起实施。
            </p>
            <p class="space_content bold" style="margin-left:2rem;text-indent:0rem;">
            附件：  
            </p>
            <p class="space_content" style="margin-left:4rem;text-indent:0rem;">SF/T 0092-2021 血液中扑草净等20种除草剂的液相色谱-串联质谱检验方法. 
<br/>SF/T 0093-2021 血液中卡西酮等37种卡西酮类新精神活性物质及其代谢物的液相色谱-串联质谱检验方法. 
<br/>SF/T 0094-2021 毛发中5F-MDMB-PICA等7种合成大麻素类新精神活性物质的液相色谱-串联质谱检验方法. 
<br/>SF/T 0095-2021 人身损害与疾病因果关系判定指南. 
<br/>SF/T 0096-2021 肢体运动功能评定. 
<br/>SF/T 0097-2021 医疗损害司法鉴定指南. 
<br/>SF/T 0098-2021 大麻的法医学STR遗传标记检验要求. 
<br/>SF/T 0099-2021 道路交通设施安全技术状况鉴定规范. 
<br/>SF/T 0100-2021 车辆火灾痕迹物证鉴定技术规范. 
<br/>SF/T 0101-2021 精神障碍者诉讼能力评定. 
<br/>SF/T 0102-2021 文件上可见指印形成过程鉴定技术规范. 
<br/>SF/T 0103-2021 文件相似性鉴定技术规范. 
<br/>SF/T 0104-2021 银行卡侧录器鉴定技术规范. 
<br/>SF/T 0105-2021 存储介质数据镜像技术规程. 
<br/>SF/T 0106-2021 人像鉴定中人脸识别技术检验规范. 
<br/>SF/T 0107-2021 塑料物证鉴定规范. 
<br/>SF/T 0108-2021 油漆检验 裂解气相色谱/质谱法. 
<br/>SF/T 0109-2021 环境损害司法鉴定中居住环境噪声的测量与评价. 
<br/>SF/T 0110-2021 司法鉴定标准化工作指南. 
<br/>SF/T 0111-2021 法医临床检验规范. 
<br/>SF/T 0112-2021 法医临床影像学检验实施规范. 
<br/>SF/T 0113-2021 血液中氰化物的气相色谱-质谱和气相色谱检验方法. 
<br/>SF/T 0114-2021 生物检材中吗啡、O6-单乙酰吗啡和可待因的检验方法. 
<br/>SF/T 0115-2021 血液中45种有毒生物碱的液相色谱-串联质谱检验方法. 
<br/>SF/T 0116-2021 血液、尿液中苯丙胺类兴奋剂、哌替啶和氯胺酮的检验方法. 
<br/>SF/T 0117-2021 生物学全同胞关系鉴定技术规范. 
<br/>SF/T 0118-2021 油漆物证鉴定规范. 
<br/>SF/T 0119-2021 声像资料鉴定通用规范. 
<br/>SF/T 0120-2021 录音真实性鉴定技术规范. 
<br/>SF/T 0121-2021 录音内容辨听技术规范. 
<br/>SF/T 0122-2021 语音同一性鉴定技术规范. 
<br/>SF/T 0123-2021 录像真实性鉴定技术规范. 
<br/>SF/T 0124-2021 录像过程分析技术规范. 
<br/>SF/T 0125-2021 人像鉴定技术规范. 
<br/>SF/T 0126-2021 物像鉴定技术规范. 
          </p>
          <p class="space_content bold" style="margin-left:2rem;text-indent:0rem;">
            详情见原文链接：
            </p>
            <a target="_blank" style="word-wrap:break-word;" href="http://www.moj.gov.cn/pub/sfbgw/zwxxgk/fdzdgknr/fdzdgknrlzyj/lzyjsfhybzj/202112/t20211207_443298.html">
              http://www.moj.gov.cn/pub/sfbgw/zwxxgk/fdzdgknr/fdzdgknrlzyj/lzyjsfhybzj/202112/t20211207_443298.html
            </a>
        </div>
        <div class="flex wrap col442" style="width:100%;text-align:left;margin-top:1rem">
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex" style="margin-right:10%">
                    <p style="white-space:nowrap">上一篇：</p>无
                    
                </div>
            </div>
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex">
                    <p style="white-space:nowrap">下一篇：</p>
                    <router-link class="a" :to="'/news/law/2'"><p class="ellipsis1">《司法鉴定程序通则》</p></router-link>
                    
                </div>
            </div>
            <div class="col2 flex  end1 center">
                <router-link class="a" style="color:#fff" :to="'/news/law'"><el-button type="primary">返回列表</el-button></router-link >
                
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return { 
            data:{

            }
        }
    },
}
</script>
<style scoped>

</style>